import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import StaticSection from "../components/StaticSection"
import Contact from "../components/Contact"

const Content = styled.section`
  padding: ${props => (props.itemBelow ? "32px 16px 0" : "32px 16px 64px")};
  & h4 {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.3px;
    color: #062964;
    line-height: 30px;
    padding-bottom: 8px;
    position: relative;
    font-weight: 900;
    margin-bottom: 16px;
  }

  & ul {
    list-style: initial;
    padding-left: 24px;
    & li {
      font-size: 20px;
      line-height: 32px;
      color: #3a465b;
      &:first-child {
        margin-top: 6px;
      }
    }
  }
  & .section-desc {
    line-height: 25px;
    letter-spacing: 0.3px;
    color: #3a465b;
  }
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    padding: 0 16px;
    align-self: center;
    grid-area: 3 / 1 / 3 / 3;
    & .section-desc {
      font-size: 20px;
      line-height: 32px;
      margin-top: 16px;
    }
  }
`

const Wspolpraca = () => {
  const { imageMobile, imagePc } = useStaticQuery(graphql`
    query {
      imageMobile: file(relativePath: { eq: "wspolpracaMobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imagePc: file(relativePath: { eq: "pracujznamiPC.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <StaticSection
        title="Pracuj z nami"
        desc="beatae inventore voluptatibus maiores
        architecto ea quos commodi, sint expedita harum natus, explicabo. "
        img={imageMobile}
        imgPc={imagePc}
        buttonText="Zadzwoń"
        link="tel:123525346"
        sectionTitle="Sit amet consectetur"
        sectionDesc={
          <>
            <p className="section-desc">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat
              perferendis molestiae beatae inventore voluptatibus maiores
              architecto ea quos commodi, sint expedita harum natus, explicabo
              facere repudiandae neque voluptatum recusandae, atque
              reprehenderit! Et temporibus laboriosam iste tempore molestias,
              dolorum sint reiciendis!
            </p>{" "}
            <p className="section-desc">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat
              perferendis molestiae beatae inventore voluptatibus maiores
              architecto ea quos commodi, sint expedita harum natus, explicabo
              facere repudiandae neque voluptatum recusandae, atque
              reprehenderit! Et temporibus laboriosam iste tempore molestias,
              dolorum sint reiciendis!
            </p>{" "}
          </>
        }
        listTitle="Opis stanowiska: "
        listItems={[
          <p>neque voluptatum recusandae, atque</p>,
          <p>neque voluptatum recusandae, atque</p>,
          <p>neque voluptatum recusandae, atque</p>,
          <p>neque voluptatum recusandae, atque</p>,
        ]}
        listButton="Zadzwoń"
        listButtonHref="tel:123452352"
        additionalSection={
          <Content>
            <div>
              <h4>Wymagania:</h4>
              <ul>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
              </ul>
            </div>
            <div>
              <h4>Oferujemy:</h4>
              <ul>
                <li>
                  <strong>neque voluptatum recusandae, atque</strong>
                </li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
                <li>neque voluptatum recusandae, atque</li>
              </ul>
              <p className="section-desc" style={{ marginTop: 16 }}>
                neque voluptatum recusandae, atque:{" "}
                <a href="mailto:lorem@ipsum.pl">lorem@ipsum.pl</a>
              </p>
            </div>
          </Content>
        }
      />
      <Contact />
    </Layout>
  )
}

export default Wspolpraca
